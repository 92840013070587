import * as Styled from 'components/ToolkitV2/CardV2/enhancements/WithHighlight/WithHighlight.styled';

import type { WithHighlightProps } from 'components/ToolkitV2/CardV2/enhancements/WithHighlight/WithHighlight.typed';

function WithHighlight(props: WithHighlightProps) {
  const { children, highlight, highlightText, isGrid, className } = props;

  return (
    <Styled.Container
      highlight={highlight}
      isGrid={isGrid}
      className={className}
    >
      {highlight && highlightText && (
        <Styled.Highlight highlight={highlight}>
          {highlightText}
        </Styled.Highlight>
      )}
      {children}
    </Styled.Container>
  );
}

export { WithHighlight };
