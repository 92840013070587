import type { ReactNode } from 'react';
import styled from 'styled-components';

import { BlackBoxBase } from 'components/Toolkit/Tags/styles/mediaStyles';

export interface MediaHighlightProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

const SMediaHighlight = styled.button`
  ${BlackBoxBase};
  padding: ${({ theme }) => theme.spacing.S4};
  cursor: pointer;
`;

function MediaHighlight({
  children,
  className,
  onClick,
  ...rest
}: MediaHighlightProps) {
  return (
    <SMediaHighlight className={className} onClick={onClick} {...rest}>
      {children}
    </SMediaHighlight>
  );
}

export { MediaHighlight };
