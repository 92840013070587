import Cookies from 'universal-cookie';

type useCookiesProps = {
  name: string;
};

type setCookieProps = {
  value: string | object;
  options?: CookieOptions;
};

type CookieOptions = {
  path: string;
  domain?: string;
  maxAge?: number;
};

const useCookies = (props: useCookiesProps) => {
  const { name } = props;
  const cookies = new Cookies();

  function get() {
    return cookies.get(name);
  }

  function set(props: setCookieProps) {
    const { value, options } = props;

    cookies.set(name, value, options);
  }

  return { get, set };
};

export { useCookies };
