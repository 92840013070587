import styled from 'styled-components';

import type { ThemeTypes } from 'types/Theme.namespace';
import type { ContainerProps } from 'components/ToolkitV2/CardV2/enhancements/WithHighlight/WithHighlight.typed';

const Container = styled.div<ContainerProps>`
  position: relative;
  //TODO: may not be needed:
  height: ${({ isGrid }) => (isGrid ? '100%' : 'auto')};
  ${({ theme, highlight }) =>
    highlight && `border-top: 2px solid ${theme.colors[highlight]}`};
`;

const Highlight = styled.p<{ highlight?: ThemeTypes.Colors }>`
  ${({ theme }) => theme.fontSize.B12}
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => `${theme.spacing.S2} ${theme.spacing.S8}`};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.default};
  z-index: 10;

  ${({ theme, highlight }) => {
    if (highlight) {
      return `
        background-color: ${theme.colors[highlight]};
        color: ${theme.colors.WHITE};
        text-transform: uppercase;
      `;
    }
  }};
`;

export { Container, Highlight };
